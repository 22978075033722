// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Poker from "./Poker";
import './App.css'; // Ensure you have this for styling

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

function App() {
    // eslint-disable-next-line no-unused-vars
    const [showPoker, setShowPoker] = useState(false);

    return (
        <div>
            {/* Navigation */}
            <nav className="bottom-bar">
                <button onClick={() => {
                    setShowPoker(false);
                }}>Home
                </button>
                <button onClick={() => {
                    setShowPoker(true);
                }}>Poker
                </button>
            </nav>

            {/* Views - Always render both Home and Poker, but control visibility with CSS */}
            <div className={showPoker ? "hidden" : "visible"}>
                <Home/>
            </div>
            <div className={showPoker ? "visible" : "hidden"}>
                <div className="poker-background"><Poker/></div>
            </div>

            {/* Route Configuration */}
            <Routes>
                <Route path="/" element={<div/>}/>
            </Routes>
        </div>
    );
}

export default AppWrapper;